.container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;

    .remove-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .form-content {
        display: flex;
        gap: 10px;
    }
}