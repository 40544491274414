.login-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-form {
        width: 300px;

        .login-form-button {
            text-align: center;
        }
    }
}