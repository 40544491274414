.upload-playlist-container {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

.note-text {
    margin-bottom: 20px;

    span {
        color: red;
    }
}

.playlist-container {
    max-height: 400px;
    overflow: auto;
    margin-bottom: 20px;
}