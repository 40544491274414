.message-open {
    overflow: hidden;
    height: 40px;
    transition: height .2s;
    // display: flex;
    // justify-content: center;
    
  }
  
  .message-close {
    transition: height 0.2s;
    overflow: hidden;
    height: 0;
    // display: flex;
    // justify-content: center;
  }
  
  .success-text {
    color: green;
  }
  
  .error-text {
    color: red;
  }
  