.add-button{
    float: right;
    margin-bottom: 20px;
}

.save-button{
    text-align: center;
    margin-top: 10px;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

