.add-btn-container {
    margin-top: 10px;

    button {
        &:hover {
            border-color: rgb(94, 134, 94) !important;
        }
    }
}

.playlists-form-container {
    overflow: scroll;
    margin-top: 5px;
    max-height: 339px;
}